.sidebar {
  transition: transform 0.5s ease;
  width: 19rem;
}

.sidebar-active {
  transform: translateX(0);
}

.sidebar-hidden {
  transform: translateX(-250%);
}

.sidebar-inner {
  background-image: linear-gradient(836deg, #fbecef, #e8e6f7);
}

.total_credits {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 65px;
  height: 65px;
  background: var(--gradient-color);
  font-weight: bold;
  color: white;
  border-radius: 50%;
  padding: 0.6rem;
}

/* Styles for tablets and larger screens */
@media (max-width: 768px) {
  .container {
    flex-direction: row;
  }

  /* .sidebar {
    display: none;
  } */
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  padding: 0;
}
