.input-div {
  border-radius: 5px !important;
  padding: 6px 10px !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #c9c9c9;
}

.input-div input {
  border-radius: 0 !important;
  padding: 10px !important;
  /* background-color: #f9f9f9 !important; */
  border: 0 !important;
  outline: none;
  box-shadow: none !important;
}

.input-div textarea {
  border-radius: 0 !important;
  padding: 10px !important;
  /* background-color: #f9f9f9 !important; */
  border: 0 !important;
  outline: none;
  box-shadow: none !important;
}
