.visitor-text-area {
  resize: none;
  /* padding: 0px 0px 5px 10px; */
  background-color: #f2f2f4 !important;
  width: 100%;
}

.visitor-text-area:focus {
  border: 0 !important;
  outline: none;
  box-shadow: none;
}

.visitor-text-area {
  border: 0 !important;
  outline: none;
}
