.react-tel-input .form-control {
  font-size: initial !important;
  letter-spacing: initial !important;
  /* margin-top: initial !important; */
  /* margin-bottom: initial !important; */
  /* margin-left: initial !important; */
  /* background: initial !important; */
  height: initial !important;
  padding-left: 3rem !important;
}

.react-tel-input {
  font-family: "Poppins" !important;
}

.signup_cont {
  height: calc(100vh - 64px);
}
