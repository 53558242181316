.css-106c1u2-MuiBadge-badge {
  /* width: 26px !important;
  padding: 0 !important;
  height: 26px !important;
  border-radius: 50% !important;
  cursor: pointer; */
}

.c-badge .MuiBadge-badge {
  padding: 0 !important;
}

.c-badge-r .MuiBadge-badge {
  padding: 0 !important;
  right: 5px !important;
}
