.layout_content {
  display: flex;
  transition: all 0.5s ease 0s;
  /* margin-top: 85px; */
  /* margin-left: 10px; */
  /* padding: 5px; */
  height: calc(100vh - 128px);
  width: 100%;
  overflow-y: auto;
  margin-top: 1rem;
  padding: 2rem 13rem;
}

.layout_content .layout_route {
  /* background-color: white; */
  margin-left: 1rem;
  padding: 0 1rem;
  width: 100%;
  overflow-y: auto;
  border-radius: 0.2rem;
  height: 100%;
  /* box-shadow: 0px 0px 0.9310142993927002px 0px rgba(0, 0, 0, 0.17),
    0px 0px 3.1270833015441895px 0px rgba(0, 0, 0, 0.08),
    0px 7px 14px 0px rgba(0, 0, 0, 0.05); */
}

@media (max-width: 1328px) {
  .layout_content {
    padding: 2rem 5rem;
  }
}

/* Styles for tablets and larger screens */
@media (max-width: 768px) {
  .layout_content {
    padding: 1rem;
  }
  .menu-item-toggle {
    display: flex;
  }
}
