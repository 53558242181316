.uploader-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  margin-top: 1rem;
  border: 1.4px solid #d8d8d8;
  background-color: #f5f5f5;
  border-radius: 7px;
}
