@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-family: "Poppins", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../src/assets/images/Frame_1.svg");
}

:root {
  --primary-color: #9164ef;
  --gradient-color: -webkit-linear-gradient(left, #685ecb, #f08c96) !important;
  --border-radius: 2rem;
  --text-primary-color: ;
}
* {
  /* font-size: 12px !important; */
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Common classes */
.heading-color {
  color: #000c60 !important;
}
.gradient-color {
  background: -webkit-linear-gradient(left, #685ecb, #f08c96);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-background {
  background: -webkit-linear-gradient(left, #685ecb, #f08c96);
  color: white;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outlined_btn {
  color: black !important;
  border-color: black !important;
  text-transform: none !important;
}

.prim_btn {
  background: var(--primary-color) !important;
  border-radius: var(--border-radius) !important;
  padding: 0.6rem 4rem !important;
  text-align: center !important;
  color: white !important;
}

.empty_btn {
  color: black !important;

  text-transform: none !important;
  border: none !important;
}

/* MUI Slider CSS */
.MuiSlider-thumb {
  color: #000c60;
}
.MuiSlider-rail {
  color: #bfbfbf;
}
.MuiSlider-track {
  color: #bfbfbf;
}
.Mui-active {
  color: #000c60;
}
.Mui-disabled {
  color: #bfbfbf;
}

/* MUI Switch */

.MuiSwitch-root {
  height: 26px !important;
  padding: 0px !important;
  width: 42px !important;
}

.MuiSwitch-switchBase {
  padding: 0px !important;
  margin: 2px !important;
  transition-duration: 300ms !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #fff !important;
}

.MuiSwitch-switchBase.Mui-checked {
  transform: translateX(16px) !important;
}

.MuiSwitch-track {
  border-radius: 86px !important;
  opacity: 1 !important;
  background: #cdcdcd !important;
  opacity: 1 !important;
  border: 0 !important;
  transition: all 0.3s ease !important;
}

.Mui-checked + .MuiSwitch-track {
  background: -webkit-linear-gradient(left, #685ecb, #f08c96) !important;
}

.MuiSwitch-thumb {
  box-sizing: border-box !important;
  width: 22px !important;
  height: 22px !important;
  background-color: #f9f9f9 !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  /* width: 100% !important; */
  /* border: 1px solid #c9c9c9 !important; */
  border-radius: 5px !important;
  padding: 10px !important;
  background-color: #f9f9f9 !important;
  /* outline: none !important; */
}

select {
  width: 100% !important;
  /* border: 1px solid #c9c9c9 !important; */
  border-radius: 5px !important;
  padding: 10px !important;
  background-color: #f9f9f9 !important;
  /* outline: none !important; */
}

/* MUI TABLE HEADER */
.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  color: white !important;
  background: var(--gradient-color) !important;
}

.css-k008qs {
  color: white !important;
  background: var(--gradient-color) !important;
}

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

/* Chat Bubble  */
.triangle {
  position: absolute;
  bottom: 10px;
  width: 36px;
  height: 36px;
  background-color: #707070;
  clip-path: polygon(100% 0%, 50% 50%, 100% 100%);
  bottom: -18px;
  right: 16px;
}

.triangle-reverse {
  position: absolute;
  bottom: 10px;
  width: 36px;
  height: 36px;
  background-color: #000c60;
  clip-path: polygon(0% 0%, 50% 50%, 0% 100%);
  bottom: -18px;
  left: 30px;
}

/* Material UI popper */
.css-1s3dgf5-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 10px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  color: #64748b;
  font-weight: bolder !important;
}

input {
  /* padding: 0.8rem !important; */
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  /* color: var(--gradient-color); */
  color: var(--primary-color) !important;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
} */

/* Text Label MUI */
.mui_txt_inp label {
  top: -5px !important;
}

.mui_select div {
  padding: 10px !important;
  background: #f9f9f9 !important;
}
