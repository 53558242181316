.nav-ancher {
  display: flex;
  align-items: center;
  margin-left: 1.2rem;
  cursor: pointer;
}
.nav-ancher div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none !important;
  background-color: #9164ef;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  padding: 5px 15px;
  text-transform: uppercase;
  font-family: "Onest", sans-serif;
}
.nav-ancher div:hover {
  color: #fff;
}
.nav-ancher div img {
  width: 12px;
  height: 12px;
}

.css-hqv4f7-MuiBadge-badge {
  top: 5px !important;
  right: 6px !important;
  min-width: 10px !important;
  height: 10px !important;
  border-radius: 6px !important;
}

.inside-cont {
  margin: 0 13rem;
  padding: 1rem 0;
}

@media all and (max-width: 992px) {
  .inside-cont {
    margin: 0.25rem 5rem;
    padding: 1rem 0;
  }
}

@media all and (max-width: 646px) {
  .inside-cont {
    margin: 0.25rem 3rem;
    padding: 1rem 0;
  }
}
